import Vue from "vue"

Vue.mixin({
	methods: {
		hasAuth(perm) {
			// console.log("prem:",perm);
			// console.log("hasAuth-debug;this.$store.state.menus.permList:",this.$store.state.menus.permList);
			var authority = this.$store.state.menus.permList

			return authority.indexOf(perm) > -1
		}
	}
})