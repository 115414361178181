import axios from "axios";
import router from "./router";
import Element from "element-ui"
axios.defaults.baseURL = "http://129.211.222.48:9091"
// axios.defaults.baseURL = "http://localhost:9091"
const request = axios.create({
	timeout: 5000,
	headers: {
		'Content-Type': "application/json; charset=utf-8"
	}
})

request.interceptors.request.use(config => {
	config.headers['Authorization'] = localStorage.getItem("token")
	return config
})

request.interceptors.response.use(
	response => {

		let res = response.data
		
		if (res.code === 200) {
			return response
		} else {
			Element.Message.error(!res.msg ? '系统异常' : res.msg)
			console.log("res-----------------------",res);
			return Promise.reject(response.data.msg)
		}
	},
	error => {

		if (error.response.data) {
			error.massage = error.response.data.msg
			if(error.massage=="请先登录"||error.massage=="token异常"){
				localStorage.clear()
				sessionStorage.clear()
			}
			
		}

		if (error.response.status === 401) {
			router.push("/login")
		}
		if(error.response.data.msg != undefined){
			Element.Message.error(error.response.data.msg, {duration: 3000})
		}
		if(error.response.data.message != undefined){
			Element.Message.error(error.response.data.message, {duration: 3000})
		}
		
		return Promise.reject(error)
	}
)

export default request