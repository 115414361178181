<template>
	<div>
		<h1>欢迎使用学员管理系统</h1>
	</div>
</template>

<script>
	export default {
		name: "Index",
	}
</script>

<style scoped>



</style>